import { Link, routes } from '@redwoodjs/router'
import { Head } from '@redwoodjs/web'
import { Toaster } from '@redwoodjs/web/toast'

const SavedSearchSharesLayout = (props) => {
  return (
    <div className="rw-scaffold">
      <Head>
        <title>Search Shares</title>
      </Head>
      <Toaster />
      <header className="rw-header">
        <h1 className="rw-heading rw-heading-primary">
          <Link to={routes.searchSavedSearchShares()} className="rw-link">
            Search Shares
          </Link>
        </h1>
      </header>
      <div className="block pb-10">
        <Link to={routes.searchSavedSearches()} className="float-right">
          My Saved Searches
        </Link>
      </div>
      <main className="rw-main">{props.children}</main>
    </div>
  )
}

export default SavedSearchSharesLayout
