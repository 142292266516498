import { useEffect, useRef } from 'react'

import { motion, useAnimation, useInView } from 'framer-motion'

interface Props {
  children: JSX.Element | JSX.Element[]
  duration?: number
  delay?: number
  direction: 'up' | 'down'
}

const Reveal = ({
  children,
  duration = 0.5,
  delay,
  direction = 'up',
}: Props) => {
  const ref = useRef(null)
  const isInView = useInView(ref, { once: true })

  const mainControls = useAnimation()

  useEffect(() => {
    if (isInView) {
      mainControls.start('visible')
    }
  }, [isInView])

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={mainControls}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y: direction === 'up' ? 75 : -40 },
      }}
      transition={{ duration, delay: delay || 0 }}
    >
      {children}
    </motion.div>
  )
}

export default Reveal
