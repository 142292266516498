import type { FindNotifications } from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import NotificationItems from 'src/components/Notification/NotificationItems'
import Notifications from 'src/components/Notification/Notifications'

export const beforeQuery = (props) => {
  /*
  This cell rerenders, and therefore re-queries for data, every time
  both navigation occurs and the route nativated to has a separate reference
  to the ResponsiveLayout, which is determined in the Routes file. The polling
  specified here causes notifications to be re-fetched every X minutes, in
  the event that such a navigation has not occurred.
  */
  const minuntesForPolling = 10
  return {
    variables: props,
    fetchPolicy: 'cache-and-network',
    pollInterval: 1000 * 60 * minuntesForPolling,
  }
}

export const QUERY = gql`
  query FindNotifications {
    notifications {
      id
      createdAt
      profileId
      photoId
      isRead
      isDismissed
      title
      content
      actionUrl
      photo {
        url
      }
    }
  }
`

export const Loading = () => <Notifications notifications={[]} />

export const Empty = ({ isPage }) => {
  return <Success notifications={[]} isPage={isPage} />
}

export const Failure = ({ error, isPage }: CellFailureProps) => (
  <Success notifications={[]} error={error} isPage={isPage} />
)

export const Success = ({
  notifications,
  isPage,
  error,
}: CellSuccessProps<FindNotifications>) => {
  return isPage ? (
    <div className="flex items-center justify-center ">
      <div className="divide-y divide-gray-100 w-96">
        <NotificationItems notifications={notifications} error={error} />
      </div>
    </div>
  ) : (
    <Notifications notifications={notifications} error={error} />
  )
}
