import { Link, routes } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

import { useAuth } from 'src/auth'

type PostLayoutProps = {
  children: React.ReactNode
}

const PostsLayout = ({ children }: PostLayoutProps) => {
  const { hasRole } = useAuth()
  return (
    <div className="rw-scaffold">
      <Toaster toastOptions={{ className: 'rw-toast', duration: 6000 }} />
      <header className="rw-header">
        <h1 className="rw-heading rw-heading-primary">Posts</h1>
        {hasRole('admin') && hasRole('brokerage') && (
          <Link to={routes.newPost()} className="rw-button rw-button-green">
            <div className="rw-button-icon">+</div> New Post
          </Link>
        )}
      </header>
      <main className="rw-main">{children}</main>
    </div>
  )
}

export default PostsLayout
