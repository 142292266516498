import { StoreParams } from 'filestack-js/build/main/lib/filelink'
import { client } from 'filestack-react'
import { Photo, Profile } from 'types/graphql'

import { IMAGE_PROVIDER_CDN_URL } from 'src/util/constants'

const imageClient = client.init(process.env.REDWOOD_ENV_FILESTACK_API_KEY || '')

const getPhotoThumbnailUrl = ({
  url,
  height,
}: {
  url: string
  height: number
}): string => {
  const parts = url.split('/')
  parts.splice(
    3,
    0,
    `resize=height:${height}/rotate=deg:exif/output=compress:true/`
  )
  return parts.join('/')
}

export default getPhotoThumbnailUrl

export const getProfileThumbnailUrl = ({
  profile,
  height,
  width,
}: {
  profile: Profile
  height?: number
  width?: number
}): string => {
  const photoUrl =
    profile?.photo?.provider === 'filestack'
      ? `${IMAGE_PROVIDER_CDN_URL}/resize=${
          height ? 'height:' + height : 'width:' + width
        }/rotate=deg:exif/output=compress:true/${
          profile.photo.providerMetadata?.handle
        }`
      : profile?.photo?.url || '/images/profile.svg'
  return photoUrl
}

export const getThumbnailUrl = ({
  photo,
  height,
  width,
}: {
  photo: Photo
  height?: number
  width?: number
}): string => {
  const photoUrl =
    photo?.provider === 'filestack'
      ? `${IMAGE_PROVIDER_CDN_URL}/resize=${
          height ? 'height:' + height : 'width:' + width
        }/rotate=deg:exif/output=compress:true/${
          photo?.providerMetadata?.handle
        }`
      : photo?.url || ''
  return photoUrl
}

export const putPhotoByUrl = async (url: string, storeParams: StoreParams) => {
  return await imageClient.storeURL(url, storeParams)
}
