import { Link, routes, useMatch, useParams } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

const ProfilesLayout = ({ children }) => {
  const { brokerageId } = useParams()

  // Hide some page elements if we're on the 'create new' page of this entity
  const isNew = useMatch(
    routes.newProfile({
      brokerageId: brokerageId || '',
    })
  ).match

  return (
    <div className="rw-scaffold">
      <Toaster />
      <header className="rw-header">
        {!isNew && brokerageId && (
          <Link
            to={routes.newProfile({ brokerageId })}
            className="rw-button rw-button-green"
          >
            <div className="rw-button-icon">+</div> New Profile
          </Link>
        )}
      </header>
      <main className="rw-main">{children}</main>
    </div>
  )
}

export default ProfilesLayout
