import Editor from '@draft-js-plugins/editor'
import { CompositeDecorator, EditorState, convertFromRaw } from 'draft-js'
import { DateTime } from 'luxon'
import {
  EditNotificationById,
  FindNotifications,
  UpdateNotificationInput,
} from 'types/graphql'

import { Link, navigate, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import { Notification } from '../../../../types/graphql.d.ts'

const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation UpdateNotificationMutation(
    $id: String!
    $input: UpdateNotificationInput!
  ) {
    updateNotification(id: $id, input: $input) {
      id
      createdAt
      profileId
      photoId
      isRead
      isDismissed
      title
      content
      actionUrl
    }
  }
`

const NotificationItems = ({ notifications, error }: FindNotifications) => {
  const [updateNotification] = useMutation(UPDATE_NOTIFICATION_MUTATION, {
    onError: (error) => {
      toast.error(error.message)
    },
    //refetchQueries: [{ query: QUERY }],
  })

  const onUpdateNotification = (
    input: UpdateNotificationInput,
    id: EditNotificationById['notification']['id']
  ) => {
    updateNotification({ variables: { id, input } })
  }

  const HASHTAG_REGEX = /#[\w\u0590-\u05ff]+/g

  const findSuggestionEntities = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity()
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'mention'
      )
    }, callback)
  }

  function hashtagStrategy(contentBlock, callback, _contentState) {
    findWithRegex(HASHTAG_REGEX, contentBlock, callback)
  }

  function findWithRegex(regex, contentBlock, callback) {
    const text = contentBlock.getText()
    let matchArr, start
    while ((matchArr = regex.exec(text)) !== null) {
      start = matchArr.index
      callback(start, start + matchArr[0].length)
    }
  }

  const styles = {
    root: {
      fontFamily: "'Helvetica', sans-serif",
      padding: 20,
      width: 600,
    },
    editor: {
      border: '1px solid #ddd',
      cursor: 'text',
      fontSize: 16,
      minHeight: 40,
      padding: 10,
    },
    button: {
      marginTop: 10,
      textAlign: 'center',
    },
    handle: {
      color: 'rgb(65, 131, 196)',
      direction: 'ltr',
      unicodeBidi: 'bidi-override',
    },
    hashtag: {
      color: 'rgba(95, 184, 138, 1.0)',
    },
  }

  const HandleSpan = (props) => {
    const { mention } = props.contentState.getEntity(props.entityKey).getData()
    return (
      <Link
        style={styles.handle}
        data-offset-key={props.offsetKey}
        to={routes.profile({ id: mention.id })}
      >
        {props.children}
      </Link>
    )
  }

  const HashtagSpan = (props) => {
    return (
      <span style={styles.hashtag} data-offset-key={props.offsetKey}>
        {props.children}
      </span>
    )
  }

  const compositeDecorator = new CompositeDecorator([
    {
      strategy: findSuggestionEntities,
      component: HandleSpan,
    },
    {
      strategy: hashtagStrategy,
      component: HashtagSpan,
    },
  ])

  const NotificationContentView = ({ notification }) => {
    const editorState = notification.content
      ? EditorState.createWithContent(
          convertFromRaw(notification.content),
          compositeDecorator
        )
      : EditorState.createEmpty()
    return (
      <div className="text-gray-500 text-sm mb-1.5 dark:text-gray-400 overflow-clip overflow-ellipsis">
        <Editor
          editorKey={'editor'}
          editorState={editorState}
          readOnly={true}
          onChange={() => {}}
        />
      </div>
    )
  }

  const notificationOnClick = (notification: Notification) => {
    if (!notification.actionUrl) return
    navigate(notification.actionUrl)
    onUpdateNotification({ isRead: true }, notification.id)
  }
  const NotificationView = ({ notification }) => {
    const timeAgo = DateTime.fromISO(notification.createdAt).toRelative()

    return (
      <div
        className={`flex px-4 py-3 dark:hover:bg-gray-700 ${
          notification.isRead
            ? 'bg-gray-200 dark:bg-gray-700 hover:bg-gray-300'
            : 'hover:bg-gray-100 '
        }`}
      >
        <div className="flex" onClick={() => notificationOnClick(notification)}>
          <div className="flex-shrink-0">
            {notification.photo?.url ? (
              <img
                className="rounded-full w-11 h-11"
                src={notification.photo?.url || '#'}
                alt="img"
              />
            ) : (
              <div className=" w-11 h-11" />
            )}
            {/* <Icons.Comment /> */}
          </div>
          <div className="w-full pl-3">
            <NotificationContentView notification={notification} />
            <div className="text-xs text-blue-600 dark:text-blue-500">
              {timeAgo}
            </div>
          </div>
        </div>
        <div id="actions" className="flex">
          {/* icon for deleting the item */}
          <button
            className=" border flex-shrink-0 ml-2.5 inline-flex items-center justify-center w-5 h-5 text-gray-800 hover:text-gray-900 dark:hover:text-gray-300"
            type="button"
            aria-label="Mark notification as read"
            onClick={() => {
              onUpdateNotification(
                { isRead: !notification.isRead },
                notification.id
              )
            }}
          >
            {!notification.isRead ? (
              <div className="relative flex">
                <div className="relative inline-flex w-3 h-3 bg-blue-500 border-3 border-white rounded-full dark:border-gray-900"></div>
              </div>
            ) : (
              /* empty */ <div className="relative flex">
                <div className="relative inline-flex w-3 h-3 -top-1.5 right-3"></div>
              </div>
            )}
          </button>
          <button
            className=" border flex-shrink-0 ml-2.5 inline-flex items-center justify-center w-5 h-5 text-gray-800 hover:text-gray-900 dark:hover:text-gray-300"
            type="button"
            aria-label="Dismiss notification"
            onClick={() => {
              onUpdateNotification({ isDismissed: true }, notification.id)
            }}
          >
            <div className="relative flex">
              <div className="relative inline-flex w-3 h-3 border-3 border-white rounded-full dark:border-gray-900 text-md bottom-1 left-[.5px]">
                X
              </div>
            </div>
          </button>
        </div>
      </div>
    )
  }
  return (
    <>
      {error ? (
        <div>
          <div className="flex items-center justify-center m-4">
            <span className="rw-cell-error px-4">{error.message}</span>
          </div>
        </div>
      ) : null}
      {notifications.length === 0 && !error ? (
        <div className="flex items-center justify-center m-4 text-black cursor-default">
          You're all caught up!
        </div>
      ) : null}
      {notifications.map((notification, i) => (
        <NotificationView notification={notification} key={i} />
      ))}
    </>
  )
}

export default NotificationItems
