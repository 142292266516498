import { useEffect } from 'react'

import { initFlowbite } from 'flowbite'
import { FindNotifications } from 'types/graphql'

import { navigate, routes } from '@redwoodjs/router'

import NotificationItems from 'src/components/Notification/NotificationItems/NotificationItems'
import Reveal from 'src/components/Reveal'

const NotificationsList = ({ notifications, error }: FindNotifications) => {
  useEffect(() => {
    initFlowbite()
  }, [])

  const hasUnreadNotifications = notifications.some(
    (notification) => !notification.isRead
  )

  const Icons = {
    Comment: () => (
      <div className="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-green-400 border border-white rounded-full dark:border-gray-800">
        <svg
          className="w-3 h-3 text-white"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M18 13V5a2 2 0 00-2-2H4a2 2 0 00-2 2v8a2 2 0 002 2h3l3 3 3-3h3a2 2 0 002-2zM5 7a1 1 0 011-1h8a1 1 0 110 2H6a1 1 0 01-1-1zm1 3a1 1 0 100 2h3a1 1 0 100-2H6z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
    ),
    Inbox: () => (
      <div className="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-blue-600 border border-white rounded-full dark:border-gray-800">
        <svg
          className="w-3 h-3 text-white"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
          <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
        </svg>
      </div>
    ),
    Follower: () => (
      <div className="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-gray-900 border border-white rounded-full dark:border-gray-800">
        <svg
          className="w-3 h-3 text-white"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z"></path>
        </svg>
      </div>
    ),
    Like: () => (
      <div className="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-red-600 border border-white rounded-full dark:border-gray-800">
        <svg
          className="w-3 h-3 text-white"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
            clipRule="evenodd"
          ></path>
        </svg>
      </div>
    ),
    Video: () => (
      <div className="absolute flex items-center justify-center w-5 h-5 ml-6 -mt-5 bg-purple-500 border border-white rounded-full dark:border-gray-800">
        <svg
          className="w-3 h-3 text-white"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2 6a2 2 0 012-2h6a2 2 0 012 2v8a2 2 0 01-2 2H4a2 2 0 01-2-2V6zM14.553 7.106A1 1 0 0014 8v4a1 1 0 00.553.894l2 1A1 1 0 0018 13V7a1 1 0 00-1.447-.894l-2 1z"></path>
        </svg>
      </div>
    ),
  }

  return (
    <div>
      <button
        id="dropdownNotificationButton"
        data-dropdown-toggle="dropdownNotification"
        className=" right-12 inline-flex items-center text-sm font-medium text-center text-gray-500 hover:text-blue-400 focus:outline-none dark:hover:text-white dark:text-gray-400"
        type="button"
      >
        <svg
          className="w-6 h-6"
          aria-hidden="true"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
        </svg>
        <div className="relative flex">
          {hasUnreadNotifications ? (
            <div className="relative inline-flex w-3 h-3 bg-red-600 border border-white rounded-full -top-2 right-3 dark:border-gray-900"></div>
          ) : (
            <div className="relative inline-flex w-3 h-3 -top-2 right-3 dark:border-gray-900"></div>
          )}
        </div>
      </button>

      <div
        id="dropdownNotification"
        className="z-20 hidden w-96 text-left max-w-md bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-800 dark:divide-gray-700
        max-h-[90vh]"
        aria-labelledby="dropdownNotificationButton"
      >
        <Reveal direction="down">
          <div className="block px-4 py-2 font-medium text-center text-white rounded-t-lg bg-[#1B1C1D] dark:bg-gray-800 dark:text-white border border-white">
            Notifications
          </div>
          <div className="overflow-y-scroll divide-y divide-gray-100 dark:divide-gray-700 max-h-[60vh]">
            <NotificationItems notifications={notifications} error={error} />
          </div>
          <a
            href="#"
            className="block py-2 text-sm font-medium text-center text-gray-900 rounded-b-lg bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-700 dark:text-white"
            onClick={() => navigate(routes.notifications())}
          >
            <div className="inline-flex items-center ">
              <svg
                className="w-4 h-4 mr-2 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M10 12a2 2 0 100-4 2 2 0 000 4z"></path>
                <path
                  fillRule="evenodd"
                  d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
              View all
            </div>
          </a>
        </Reveal>
      </div>
    </div>
  )
}

export default NotificationsList
