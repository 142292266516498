import { useEffect } from 'react'

import * as Sentry from '@sentry/browser'
import { usePostHog } from 'posthog-js/react'

import { navigate, routes, useLocation } from '@redwoodjs/router'

import { useAuth } from 'src/auth'

const RequireCompletedProfileWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { pathname } = useLocation()

  const isCompleteSignupPage = pathname === routes.completeProfile()

  const {
    isAuthenticated,
    currentUser,
    hasRole,
    loading,
    client,
    reauthenticate,
  } = useAuth()

  useEffect(() => {
    async function refreshSession() {
      try {
        // Returns the session, refreshing it if necessary. The session returned can be null
        // if the session is not detected which can happen in the event a user is not
        // signed-in or has logged out. This can be simulated by clearing the local storage.
        const { data, error } = await client.auth.getSession()
        if (error) {
          Sentry.withScope((scope) => {
            scope.setTag('mechanism', 'Refresh Session Return Error')
            scope.setLevel('error')
            Sentry.captureException({ error, currentUser })
          })
        }
        if (!data?.session) {
          // Causes the login screen to appear if there's not valid session
          await reauthenticate()
        }
      } catch (caughtError) {
        Sentry.withScope((scope) => {
          scope.setTag('mechanism', 'Refresh Session Caught Error')
          scope.setLevel('error')
          Sentry.captureException({ caughtError, currentUser })
        })
      }
    }
    refreshSession()
  })

  const posthog = usePostHog()

  const myProfile = currentUser?.profile

  useEffect(() => {
    if (isAuthenticated && myProfile) {
      // Set Sentry and Posthog user
      const userIdentifiers = {
        email: myProfile?.email,
        id: myProfile?.id,
        username: myProfile?.fullName,
        name: myProfile?.fullName,
      }
      Sentry.setUser(userIdentifiers)
      posthog.identify(myProfile?.id, userIdentifiers)
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (loading || !myProfile) return

    const hasNoRole =
      !hasRole('client') &&
      !hasRole('agent') &&
      !hasRole('brokerage') &&
      !hasRole('admin')
    const isClientWithoutAgent = hasRole('client') && !myProfile?.agentId
    const isAgentWithoutBrokerage =
      hasRole('agent') && !myProfile?.brokerageProfileAssociations?.length
    const isProfileMissingInfo =
      !myProfile?.primaryLocation ||
      !myProfile?.fullName ||
      !myProfile?.acceptedTerms

    const shouldRedirectToProfileSetupPage =
      isAuthenticated &&
      !isCompleteSignupPage &&
      (isClientWithoutAgent ||
        isAgentWithoutBrokerage ||
        hasNoRole ||
        isProfileMissingInfo)

    if (shouldRedirectToProfileSetupPage) {
      navigate(routes.completeProfile())
      return
    }
  }, [currentUser?.id, myProfile?.id, loading])
  return <>{children}</>
}

export default RequireCompletedProfileWrapper
