import { Profile } from '@prisma/client'
import { createClient, SupabaseClient } from '@supabase/supabase-js'
import posthogLibrary from 'posthog-js'

import { AuthContextInterface, CurrentUser } from '@redwoodjs/auth'
import { createAuth } from '@redwoodjs/auth-supabase-web'

export interface IUseAuth<
  TUser,
  TLogInOptions,
  TLogIn,
  TLogOutOptions,
  TLogOut,
  TSignUpOptions,
  TSignUp,
  TForgotPassword,
  TResetPasswordOptions,
  TResetPassword,
  TValidateResetToken,
  TClient
> extends AuthContextInterface<
    TUser,
    TLogInOptions,
    TLogIn,
    TLogOutOptions,
    TLogOut,
    TSignUpOptions,
    TSignUp,
    TForgotPassword,
    TResetPasswordOptions,
    TResetPassword,
    TValidateResetToken,
    TClient
  > {
  isAuthenticated: boolean
  currentUser: CurrentUserAndProfile
  hasRole: (role: string) => boolean
  loading: boolean
}

export interface IProfile extends Profile {
  brokerageProfileAssociations: any
  brokerages: any
}

export interface CurrentUserAndProfile extends CurrentUser {
  profile?: IProfile
}

posthogLibrary.init(process.env.POSTHOG_TOKEN || '', {
  api_host: 'https://app.posthog.com',
})

const supabaseClient: SupabaseClient = createClient(
  process.env.SUPABASE_URL || '',
  process.env.SUPABASE_KEY || '',
  {
    auth: {
      persistSession: true,
      autoRefreshToken: true,
      detectSessionInUrl: true,
    },
  }
)

const addProfile = ({
  AuthProvider,
  useAuth,
}: {
  AuthProvider: any
  useAuth: Function
}): { AuthProvider: any; useAuth: Function; posthog: any } => {
  const _useAuth = () => {
    const useAuthResult = useAuth()
    return {
      ...useAuthResult,
      profile: useAuthResult?.currentUser?.profile,
    }
  }
  return {
    AuthProvider,
    useAuth: _useAuth,
    posthog: posthogLibrary,
  }
}

export const { AuthProvider, useAuth, posthog } = addProfile(
  createAuth(supabaseClient)
)
